//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Stats from "./Stats";
import {mapGetters, mapState} from "vuex";
import Work from "@/components/sidebar/Work";
import Contacts from "@/components/sidebar/Contacts";
import Card from "@/components/sidebar/Card";
import PersonalCertificate from "@/components/sidebar/PersonalCertificate";
import SidebarSignIcon from "@/components/icons/SidebarSignIcon";

export default {
  name: "DefaultSidebar",
  components: {SidebarSignIcon, PersonalCertificate, Card, Contacts, Work, Stats},
  computed: {
    ...mapState([
      'worker',
      'history',
      'stats',
      'email',
      'code',
      'emailError',
      'codeError',
      'hasAccessToSign',
      'hasAvailableDsSlot',
    ]),
    ...mapGetters([
      'isIdAuthMethod',
    ])
  },
}
