//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CadastreModal from "@/components/sidebar/CadastreModal";
import $ from 'jquery'

export default {
  name: 'Work',
  components: {CadastreModal},
  props: {
    history: Array,
    worker: Object
  },
  methods: {
    openModal() {
      $('#modalCadastre').modal('show')
    }
  }
}
